<template>
    <v-container fluid>
      <v-container>
        <h2 class="mb-3">Ecue : {{ Ecue.name }}</h2>
        
        <div class="d-flex justify-end py-3">
          <div>
            <router-link :to="`${$route.path}/student`" class="white--text red py-2 px-3 rounded">Listes des etudiants</router-link>
          </div>
        </div>
        <div class="mt-5 py-5 elevation-1 px-5 rounded">
          <div class="d-flex justify-space-between">
            <div>
              <h2 class="primary--text mb-5">Masse horaires</h2>
            </div>
            <v-btn
              v-if="Ecue.hourlyMass && Ecue.hourlyMass._id"
              @click="updateItem(Ecue.hourlyMass)"
              class="white--text primary"
              >Modifier
            </v-btn>
            <v-btn v-else @click="addItem()" class="white--text primary"
              >Ajouter</v-btn
            >
          </div>
  
          <v-row v-if="Ecue.hourlyMass && Ecue.hourlyMass._id">
            <v-col cols="12" md="6">
              <h3>
                <span class="">Heures total: </span>
                <span>{{ Ecue.hourlyMass.hours }} h</span>
              </h3>
              <h3 class="mt-5">
                <span>Heures restantes : </span>
                <span>{{ Ecue.hourlyMass.leftHourToShedule }} h</span>
              </h3>
            </v-col>
            <v-col cols="12" md="6">
              <h3>
                <span class="">Enseignant: </span>
                <span
                  ><span class="primary--text">{{
                    Ecue.hourlyMass.teacher.grade.name
                  }}</span>
                  {{ Ecue.hourlyMass.teacher.lastName }}
                  {{ Ecue.hourlyMass.teacher.firstName }}
                </span>
              </h3>
              <h3 class="mt-5">
                <span>Les copies d'examen sont corrigées : </span>
                <span class="green--text"
                  >{{ Ecue.hourlyMass.examIsCorrected ? "Oui" : "Non" }}
                </span>
              </h3>
            </v-col>
          </v-row>
  
          <div v-if="Ecue && Ecue.hourlyMass && Ecue.hourlyMass._id">
              <div class="d-md-flex justify-space-between mb-5 mt-12 py-5 container">
                  <h3 class="text-h5">Emploi du temps</h3>
  
                  <div class="mt-md-0 mt-4">
                      <v-btn class="orange white--text" @click="addTimesTable()">Ajouter un emploi du temps</v-btn>
                  </div>
              </div>
              <calendar-x :list="Ecue.timesTables" @edit="updateTimesTable" />
          </div>
        </div>
      </v-container>
      <v-dialog v-model="timesTableDialog" max-width="1000px" class="black">
        <editor-x
          v-if="timesTableDialog"
          :fields="timesTablesfields"
          :model="Model"
          :callback="callBack"
          @success="successEditor"
          :title="
          Model && Model._id
            ? `Modification d'un emploi du temps`
            : `Ajout d'un emploi du temps`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
        />
      </v-dialog>
      <v-dialog v-model="editorDialog" max-width="1000px" class="black">
        <editor-x
          v-if="editorDialog"
          :fields="fields"
          :model="Model"
          :callback="callBack"
          @success="successEditor"
          :title="
          Model && Model._id
            ? `Modification de la masse horaire`
            : `Ajout d'une masse horaire`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
        />
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
        <delete-dialog
          :id="Model && Model._id ? Model._id : ''"
          :callback="callBack"
          @success="successEditor"
        />
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { defaultMethods, messages } from "../../../utils/data";
  import { addHourlyMass, updateHourlyMass, deleteHourlyMass,updateTimesTable,addTimesTable } from "../../../api/user";
  import EditorX from "../../../components/universal/EditorX.vue";
  import DeleteDialog from "../../../components/universal/DeleteDialog.vue";
  import { functions ,rules} from "@/utils/constant";
  import CalendarX from '../../../components/universal/CalendarX.vue';
  
  export default {
    name: "Ue",
    components: {
      EditorX,
      DeleteDialog,
      CalendarX,
    },
    data: () => ({
      callBack: "",
      searchData: "",
      dialog: false,
      loading: false,
      timesTableDialog: false,
      headers: [
        {
          text: "Nom",
          align: "start",
    sortable: true,
          value: "name",
        },
        {
          text: "Acronyme",
    sortable: true,
          value: "acronym",
        },
        {
          text: "Description",
    sortable: true,
          value: "description",
        },
        {
          text: "Crédit",
    sortable: true,
          value: "unit",
        },
        {
          text: "Actions",
          width: "150px",
          align: "left",
          value: "actions",
    sortable: true,
        },
      ],
      editedIndex: -1,
      Model: {},
      defaultItem: {},
      editorDialog: false,
      deleteDialog: false,
    }),
    mounted() {
      this.init();
    },
    computed: {
      ...mapGetters(["Ecue", "Ue", "Teachers", "Ecues","Halls"]),
  
      fields() {
        return [
          {
            name: "hours",
            label: "Heures",
            type: "Number",
            col: 12,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
           
          },
          {
            name: "teacher",
            label: "Enseignant",
            type: "Select",
            col: 12,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
            select: {
              store: {
                action: this.getTeachers,
                params: {},
                getter: this.Teachers,
                fieldName: "firstName",
              },
            },
          },
          {
            name: "examIsCorrected",
            label: "Les copies d'examen sont corrigées",
            type: "Radio",
            col: 12,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
            radio: {
              data: functions.getBoolsData(),
              fieldName: "name",
              fieldValue: "value",
            },
          },
          {
            name: "ecue",
            label: "",
            type: "Static",
            value: this.$route.params.id,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
          },
        ];
      },
      timesTablesfields() {
        return [
            {
            name: "type",
            label: "Type",
            type: "Radio",
            col: 12,
            md: 6,

            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
            radio: {
              data: functions.getTimesTableTypes(),
              fieldName: "value",
              fieldValue: "value",
            },
          },
          {
            name: "hall",
            label: "Salle",
            type: "Select",
            col: 12,
            md: 6,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
            select: {
              store: {
                action: this.getHalls,
                params: {},
                getter: this.Halls,
                fieldName: "name",
              },
            },
          },
  
        
          {
            name: "date",
            label: "Date",
            type: "Date",
            col: 12,
            md: 6,
            isRequiredForUpdate: true,
            isRequiredForAdd: false,
            existForUpdate: true,
            existForAdd: false,
            range: false,
            multiple: false,
          },
          {
            name: "dates",
            label: "Dates",
            type: "Date",
            col: 12,
            md: 6,
            isRequiredForUpdate: false,
            isRequiredForAdd: true,
            existForUpdate: false,
            existForAdd: true,
            range: false,
            multiple: true,
          },
          {
            name: "startHour",
            label: "Heure début",
            type: "Time",
            col: 12,
            md: 6,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
          },
          {
            name: "color",
            label: "Couleur",
            type: "Color",
            col: 12,
            md: 6,
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
          },
          {
            name: "duration",
            label: "Nombre d'heures",
            type: "Timer",
            col: 12,
            md: 6,

            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            condition:{
              if:{
                fieldName:"exam",
                fieldValue:"course",
                operation:'='
              }
            },
            existForAdd: true,
              rules:[
                 rules.max(Number(this.Ecue.hourlyMass.remainingHours))
            ]
          },

         
          {
            name: "hourlyMass",
            label: "",
            type: "Static",
            value: this.Ecue && this.Ecue.hourlyMass ? this.Ecue.hourlyMass._id : '',
            isRequiredForUpdate: true,
            isRequiredForAdd: true,
            existForUpdate: true,
            existForAdd: true,
          },
        ];
      },
    },
  
    watch: {},
    methods: {
      ...mapActions(["getEcue", "getTeachers", "getEcues","getHalls"]),
      closeForm() {
        this.Model = {};
        this.editorDialog = false;
        this.editedIndex = -1;
      },
  
      async init() {
        this.loading = true;
        try {
          await this.getEcue({ id: this.$route.params.id });
        } catch (error) {
          defaultMethods.dispatchError(
            this.$store,
            messages.failedToLoad("la faculté")
          );
        }
        this.loading = false;
      },
      successEditor() {
        this.editorDialog = false;
        this.deleteDialog = false;
        this.timesTableDialog = false;
        this.init();
      },
      addItem() {
        this.editedIndex = -1;
        this.Model = {};
        this.callBack = addHourlyMass;
        this.editorDialog = true;
      },
    
      updateItem(item) {
        this.editedIndex = 1;
        this.Model = Object.assign({}, item);
        this.editorDialog = true;
        this.callBack = updateHourlyMass;
      },
        addTimesTable() {
        this.editedIndex = -1;
        this.Model = {};
        this.callBack = addTimesTable;
        this.timesTableDialog = true;
      },
      updateTimesTable(item) {
        this.editedIndex = 1;
        this.Model = Object.assign({}, item);
        this.timesTableDialog = true;
        this.callBack = updateTimesTable;
      },
  
      deleteItem(item) {
        this.deleteDialog = true;
        this.Model = Object.assign({}, item);
        this.callBack = deleteHourlyMass;
      },
    },
  };
  </script>
  
  <style >
  </style>
  